.custom-fileUploader{
  background: #EBF5FF;
  border: 1px dashed #C3DDFD;
  padding: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  color: #014C8C;
  cursor: pointer;
  margin-bottom: 10px;
  .icon{
    stroke-width: 1px;
    margin-bottom: 10px;
  }
  span{
   font-size: 16px;
  }
}
.guZdik{
  border: 1px dashed #C3DDFD !important;
  height: 140px !important;
  flex-direction: column;
  justify-content: center;
  padding: 30px!important;
}
.jWkLDY,{
  flex-grow: 0 !important;
}
.jWkLDY > span{
  font-size: 16px !important;
  color: #014C8C !important;
  margin-top: 15px;
}