

.more-action {
    cursor: pointer;
    .icon[aria-expanded="true"]{
        background-color: #E8F4FF !important;
    }
    svg {
        color: rgba(48, 20, 0, 1);
   }
    .shadow{
        box-shadow: 0px 4px 6px -1px rgba(0, 0, 0, 0.10);
        border: 0 !important;
    }
}
tr:hover .more-action {
    .icon {
        background-color: #F9FAFB;
    }
}