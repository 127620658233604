.org-detail {

    input, textarea, select{
        border-radius: 2px!important;
        border-color: #ECECEC!important;
        background: white!important;
    }
    .focus\:ring-cyan-500:focus {
        --tw-ring-color: #AB9973!important;
    }
    .focus\:border-cyan-500:focus {
        border-color: transparent!important;
    }

    // overflow: hidden;
    .org-top {
        height: 200px;
    }

    .banner-context {
        z-index: 1;
    }
    .tab-content{
        margin-top: 30px;
    }
    @media screen and (min-width: 640px) {
        .action-table {
            position: absolute;
            top: 0;
            right: 0;
            margin-top: -85px;
            z-index: 10;
            width: calc(100% - 330px);
        }
        .action-search {
            max-width: calc(100% - 155px);
        }
        
    }
    @media screen and (max-width: 640px) {
        .org-top {
            height: auto;
            min-height: 130px;
            padding-top: 15px;
            padding-bottom: 15px;
            text-overflow: ellipsis;
        }
        .banner-image {
            width: 40px;
            height: 40px;
        }
        .banner-context {
            padding-left: 15px;
            font-size: 12px;

            .title {
                font-size: 20px;
            }

            .context{
               display: block;
            }
        }
        .description {
            .title {
                font-size: 18px;
            }
            .content {
                font-size: 14px;
            }

        }

        .action {
            margin-top: 15px;
            padding: 15px;
            .title {
                font-size: 18px;
            }
            .content {
                font-size: 14px;
            }
        }

        .avatar {
            width: 90px;
            height: 90px;
        }
        .banner {
            padding-left: 15px;
            padding-right: 15px;
        }
        .row {
            display: block;
        }
        .row-item {
            margin-top: 0;
            padding-top: 2px;
            padding-bottom: 3px;
        }

        .row .left{
            padding-left: 30px;
        }
        .box {
            padding: 15px;
            margin-bottom: 15px;
        }
        .tab-content {
            padding-left: 15px;
            padding-right: 15px;
        }
    }
    
    .avatar:hover .icon {
    opacity: 1;
    }
}
