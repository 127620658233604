@import '~flowbite';
@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body,
#root {
  height: 100%;
  font-family: "Inter", sans-serif;
}

.overflow-y {
  overflow-y: scroll;
}

.wrapper-content {
  min-height: 100%;
  display: grid;
  grid-template-rows: 1fr auto;
}

.spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
}

.p-50 {
  padding: 50px;
}

.mt-50 {
  margin-top: 50px;
}

.mb-50 {
  margin-bottom: 50px;
}

.mb-20 {
  margin-bottom: 20px;
}

.w-screen {
  width: 435px;
}

.h-full-important {
  height: 100% !important;
}

@media (min-width: 640px) {
  .sm\:border-l-3 {
    border-left-width: 3px;
  }
}

.h-32 {
  height: 8rem;
}
.pt-14 {
  padding-top: 3.5rem;
}

.hover\:block:hover {
  display: block;
}

@media screen and (max-width: 1024px) and (min-width: 640px){
  .hidden-tablet {
      display: none !important;
  }
}