.selected-location-container {

  // Location
  .select-location {
    //margin-left: 20px;
    display: flex;
    align-items: center;
    background-color: #E8F4FF;
    border-radius: 4px;
    &:hover {
      box-shadow: 0 0 2px 2px #FFE6B1;
    }
    .css-b62m3t-container {
      width: 100%;
    }

    .css-1fdsijx-ValueContainer {
      padding: 0 5px;
    }

    .css-13cymwt-control,
    .css-t3ipsp-control {
      min-height: 40px;
      max-height: 40px;
      border-radius: 4px;
      background-color: #E8F4FF;
      border: 0;
      box-shadow: unset !important;
      cursor: pointer;

      &:hover,
      &:active,
      &:focus,
      &:focus-within {
        background-color: rgba(255, 255, 255, 0.05);
      }

      .css-1xc3v61-indicatorContainer,
      .css-15lsz6c-indicatorContainer {
        color: #014C8C !important;
        font-size: 12px;
        font-weight: 400;
      }
    }

    .css-1u9des2-indicatorSeparator {
      width: 0;
    }

    &.one {
      height: 40px;
      padding-left: 10px;
    }

    .select-label {
      display: flex;
      align-items: center;
      font-size: 12px;
      font-weight: 400;
      color: #014C8C;
      max-width: 300px;
      flex: 1;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      .icon{
        background: #014C8C;
        height: 24px;
        width: 24px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 4px;
        margin-right: 10px;
      }
      .text-name {
        font-weight: normal;
        font-size: 14px;
        max-width: 300px;
        flex: 1;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .text-member {
        font-weight: 300;
        font-size: 11px;
        max-width: 134px;
      }
    }

  }

  //end select Location

  .css-1nmdiq5-menu {
    width: 360px;
    z-index: 10;
    border-radius: 4px;
    box-shadow: 0 0 0 1px hsla(0, 0%, 0%, 0.05), 0 4px 10px hsla(0, 0%, 0%, 0.051);
    margin-bottom: 2px;
    margin-top: 2px;

    .select-options {
      padding: 0;

      > div {
        padding-left: 10px;
        margin-bottom: 5px;

        &:active {
          background-color: #F9FAFB !important;
        }
      }

      .css-l72r5l-option, .css-1pzmrfp-option {
        background-color: #E8F4FF;
        cursor: pointer;
      }
    }

    .select-label {
      .text-name {
        font-weight: 500;
        font-size: 14px;
        color: #014C8C;
      }

      .text-member {
        font-weight: 400;
        font-size: 12px;
        color: #6B7280;

      }
    }
  }


  .site-admin {
    margin-left: 20px;

    p {
      padding-top: 3px;
    }

    .site-admin-icon {
      border-radius: 3px;
      width: 35px;
      height: 35px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #EBF5FF;

      svg {
        color: #FFFFFF !important;
      }
    }

    // p {
    //     color: white;
    // }
  }

  //  custom input in search location

  .select-custom .react-select__indicator svg {
    color: #014C8C;
  }
  .select-custom .css-1xc3v61-indicatorContainer {
    padding: 0;
  }
  .css-1dimb5e-singleValue {
    color: #014C8C;
    //background: url("../../src/assets/image/svg/location.svg") no-repeat left;
    //padding: 5px 0 5px 30px;
  }
  .css-qbdosj-Input{
    //background: #E8F4FF;
  }
  .select-custom .react-select__value-container {
    min-height: 40px;
  }
  .css-15lsz6c-indicatorContainer{
    padding: 0;
  }
  .select-custom .css-1n6sfyn-MenuList {
    max-height: 300px;
    padding: 15px 0;
  }
  .css-1hb7zxy-IndicatorsContainer {
    padding-right: 5px;
  }
}


@media screen and (max-width: 640px) {
  .selected-location-container {
    width: 100% !important;
  }
}