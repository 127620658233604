.custom-input-count {
  input {
    border-color: #ECECEC;
    background: transparent;
    border-radius: 2px;
    height: 44px;
  }
}

.status3column {
  display: flex;
  padding: 15px 30px;
  .column1{
    flex: 1;
  }
  .column2{
    flex: 1;
  }
  .column3{
    flex: 1;
  }
  @media screen and (max-width: 1120px) {
    flex-direction: column;
    .column2{
      margin: 15px 0;
    }
  }
}

.purchase-order-detail {
  padding: 30px;
  .table-scroll{
    display: block;
    overflow-x: auto;
    white-space: nowrap;
  }
  .item {
    .select-custom {
      width: 400px
    }
  }

  .upc {
    width: 110px;

    label {
      display: block;
    }
  }

  .cases {
    input {
      width: 110px;
    }
  }

  .cost-pc {
    label {
      width: 80px;
      display: block;
    }
  }

  .unit-pc {
    label {
      width: 70px;
      display: block;
    }
  }

  .total {
    label {
      display: block;
      width: 100px;
    }
  }

  .order {
    label {
      display: block;
      width: 120px;
    }
  }

  .action {
    width: 110px;
  }

  .group-button-footer {
    display: flex;
    margin-top: 15px;
    justify-content: space-between;
    align-items: center;

    .left {
      gap: 1rem;
      display: flex;
    }

    .right {
      gap: 1rem;
      display: flex;
    }
  }

  @media screen and (max-width: 1040px) {
    .group-button-footer {
      .left {
        flex-direction: column;
      }

      .right {
        flex-direction: column;
      }
    }
  }
}