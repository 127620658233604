.user-profile {
  .user-dropdown {
    .dropdown-item {
      &.bg-gray-100 {
        background-color: #5ba1b7 !important;
      }

      overflow: auto;
      width: max-content;
      border: solid 1px rgb(226 232 240);
      border-radius: 5px;
      position: absolute;
      background-color: rgb(255 255 255);
      margin-top: 5px;
      box-shadow: 1px;
      padding: 3px 0;
      top: 45px;
      right: 0;
      z-index: 100;
    }

   .scanner-login{
     margin: 10px;
     display: flex;
     padding: 10px;
     justify-content: center;
     align-items: center;
     align-self: stretch;

     border-radius: 2px;
     border: 1px dashed #A9D8FF;
     background: var(--lightPrimary, #E8F4FF);
     color: var(--Primary, #014C8C);

     font-family: Inter;
     font-size: 14px;
     font-style: normal;
     font-weight: 600;
     line-height: 14px; /* 100% */
   }
  }

}