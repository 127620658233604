$primary: #301400;
$primary_light: #FFEDEB;
$primary_button: #7e3f00;
$second_button: #944A01;
$secondary: #AB9973;
$third_button: #84746A;

$active: #006783;
$inactive: #BA1A1A;
$primary_text: #301400;
$second_text: #944A01;
$next_back_icon: #F3F4F6;

$text_placholder: #84746A;
$border: #ECECEC;
$border_button: rgba(132, 116, 106, 1);
$white_hover: rgb(226 224 223);