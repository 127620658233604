@import '../../assets/scss/variable';

.p-treetable-toggler{
  svg{
      margin-right: 5px;
  }
}
.p-treetable-tbody{
  tr{
    border-bottom: solid 1px #F3F4F6;
  }
  .p-row-odd{
    //background: #FAFBFC;
  }
  .group-name{
    line-height: 14px;
    align-self: center;
    vertical-align: MIDDLE;
    display: flex;
    align-items: CENTER;
    height: 50px;
  }
}