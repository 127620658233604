.form-update-profile {
    .user-profile-page {
        width: 50%;
        padding: 30px;
    }
    input, textarea, select{
        border-radius: 2px!important;
        border-color: #ECECEC!important;
        background: white!important;
    }
    .focus\:ring-cyan-500:focus {
        --tw-ring-color: #AB9973!important;
    }
    .focus\:border-cyan-500:focus {
        border-color: transparent!important;
    }
}

@media screen and (max-width: 800px) {
    .form-update-profile {
        .user-profile-page {
            width: 70% !important;
            padding: 30px;
        }
    }
}

@media screen and (max-width: 470px) {
    .form-update-profile {
        .user-profile-page {
            width: 100% !important;
            padding: 30px;
            margin: 0 20px;
        }
    }
}