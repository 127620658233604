.role-management {
    .permission-column-width {
        width: 50%;
    }
    .permission-text{
        > span {
            color: #111928;
            font-weight: 400;
            font-size: 14px;
            line-height: 150%;
        }
    }

    .min-w-20{ 
        min-width: 5rem;
    }
}