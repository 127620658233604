.QRcode {
    display: flex;
    margin-top: 30px;
    padding: 14px 14px 20px 14px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 380px;
    border-radius: 10px;
    background: var(--Primary, #014C8C);
    span{
        color: white;
        font-family: Inter;
        font-size: 24px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px; /* 100% */
        margin-top: 15px;
    }
}
