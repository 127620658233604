.input-text {
  input {
    height: 48px;
    border-color: #ECECEC;
    border-radius: 2px;
    padding-right: 35px;

    &:hover {
      border-color: #AB9973;
    }
  }

  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  .holder {
    font-size: 14px;
    line-height: 16px;
    position: absolute;
    color: #6B7280;
    left: 5px;
    top: 24px;
    transform: translateY(-50%);
    transition: all 0.25s ease;
    pointer-events: none;
    background: none;
    padding: 0 5px;
    z-index: 1;
  }

  .label {
    background: white;
    border-radius: 6px;
    left: 10px;
    top: 0;
    font-size: 12px;
  }
}