@import '../../assets/scss/variable';

.action-table {

  .action-search{
    width: 300px;
  }
  input {
    &:focus {
      border: 0;
      box-shadow: none;
    }
  }
  .action-add {
    button {
      background-color: $secondary !important;
      height: 34px;
      padding: 0 10px;

      &:hover {
        box-shadow: 0 0 2px 2px #FFE6B1;
      }
    }
  }

  .action-search {
    &:focus {
      border-color: #63574f;
    }
  }
  .left{
    display: flex;
  }
}

@media screen and (max-width: 600px) {
  .action-table {
    flex-direction: column;

    .action-button {
      flex-wrap: wrap;
      align-items: center;
      margin-top: 15px;
    }
  }
}

@media screen and (max-width: 640px) {
  .action-table {
    display: block;
    padding: 10px 0;
    margin-right: 0;

    input {
      box-shadow: unset !important;
    }

    .action-search {
      width: 100%;
      margin-bottom: 10px;
      margin-right: 0 !important;
    }

    .action-button {
      justify-content: flex-start;
    }

    .action-add {
      width: unset !important;

      button {
        background-color: $second_button !important;
      }
    }
  }
}

@media screen and (max-width: 1460px) {
  .stock-list {
    .action-table {
      flex-direction: column;

      .action-button {
        flex-wrap: wrap;
        align-items: center;
        margin-top: 15px;
      }
    }
  }
}

.at-requestedPOs {
  @media screen and (max-width: 1400px) {
    flex-direction: column;
  }

  .desktop {
    display: flex;
    flex-wrap: wrap;
    @media screen and (max-width: 1400px) {
      margin-bottom: 15px;
    }

    @media screen and (max-width: 980px) {
      flex-direction: column;
      .mt15 {
        margin-top: 15px;
      }
    }

    @media screen and (max-width: 720px) {
      .mt15 {
        margin-top: 15px;
      }
    }
    @media screen and (max-width: 700px) {
      .mt15 {
        margin-top: 0;
      }
    }
  }

  .responsive {
    @media screen and (max-width: 700px) {
      flex-direction: column;
    }
  }

  .action-search {
    input{
      width: 210px;
    }

    @media screen and (max-width: 1440px) {
      input{
        width: 220px;
      }
    }

    @media screen and (max-width: 1260px) {
      input{
        width: 180px;
      }
    }

    @media screen and (max-width: 960px) {
      input{
        width: 120px;
      }
    }
    @media screen and (max-width: 980px) {
      margin-right: 0;
      margin-bottom: 10px;

      input{
        width: 100%;
      }
    }
    @media screen and (max-width: 700px) {
      width: 100%;
      margin-bottom: 10px;
    }
  }

  input {
    &:focus {
      border: 0;
      box-shadow: none;
    }
  }

  .css-13cymwt-control {
    height: 36px;
    min-height: 36px;
  }

  .select-custom {
    flex: none;
    width: 180px;
    margin-right: 15px;

      @media screen and (max-width: 980px) {
        width: 100%;
        margin: 0 0 10px 0;
      }


    .react-select__value-container {
      min-height: 36px;
      width: 100%;

    }

    .react-select__placeholder {
      color: #6B7280;
    }
  }

  .datepicker {
    @media screen and (max-width: 980px) {
      width: calc(50% - 15px);
      margin-left: 15px;
    }
    @media screen and (max-width: 700px) {
      width: 100%;
      margin-left: 0;
    }

    @media screen and (max-width: 660px) {
      margin: 10px 0;
    }

    input {
      border: solid 1px #E5E7EB;
      height: 36px;
      width: 200px;
      @media screen and (max-width: 1520px) {
        width: 180px;
      }
      @media screen and (max-width: 1440px) {
        width: 140px;
      }
      @media screen and (max-width: 1260px) {
        width: 120px;
      }

      @media screen and (max-width: 1230px) {
        width: 140px;
      }

      @media screen and (max-width: 980px) {
        width: 100%;
      }

      @media screen and (max-width: 720px) {
        width: 100%;
      }
      color: #111928;
      font-size: 14px;

      &::placeholder {
        color: red;
      }
    }
  }

  //custom flatpickr-input
  .flatpickr-input[readonly] {
    width: 220px;
    @media screen and (max-width: 980px) {
      width: 100%;
    }
  }
}


//custom-flatpickr-date-range
.custom-date-range {
  display: flex;
  align-items: center;
  border: solid 1px #E5E7EB;
  border-radius: 2px;
  padding-right: 5px;
  margin-right: 15px;
  width: 240px;
  height: 36px;
  @media screen and (max-width: 1170px) {
    margin-top: 15px;
  }
  @media screen and (max-width: 980px) {
    margin-right: 0;
    margin-top: 0;
    width: 100%;
  }
  input {
    border: none;
    font-size: 14px;
    padding: 5px 10px;

    &:focus {
      box-shadow: transparent;
      --tw-ring-color: transparent !important;
    }
  }

}

//custom-flatpickr-date-range

.custom-date-range-modal {
  display: flex;
  align-items: center;
  border: solid 1px #E5E7EB;
  border-radius: 2px;
  padding-right: 5px;
  height: 36px;
  @media screen and (max-width: 1160px) {
    margin-top: 15px;
  }
  @media screen and (max-width: 980px) {
    margin-right: 0;
  }
  input {
    border: none;
    font-size: 14px;
    padding: 5px 10px;

    &:focus {
      box-shadow: transparent;
      --tw-ring-color: transparent !important;
    }
  }

}
.date-range48{
  height: 48px;
  width: 100%;
  padding-right: 10px;
}
//custom date-range in filter

.flatpickr-weekdays {
  background: transparent !important;
  height: 22px;
}

span.flatpickr-weekday {
  font-size: 90%;
  background: transparent !important;
  color: #6B7280 !important;
  font-weight: 300 !important;
}

.flatpickr-months {
  .flatpickr-month {
    background: #FFFFFF !important;
    color: #000000 !important;
  }

  .flatpickr-prev-month, .flatpickr-next-month {
    svg {
      color: #4B5563;
      fill: #4B5563;
    }
  }
}

.flatpickr-current-month .flatpickr-monthDropdown-months, .flatpickr-current-month input.cur-year {
  background: transparent !important;
  font-size: 15px !important;
}

.flatpickr-day {
  color: #111928 !important;
}

.flatpickr-days {
  border-left: 0 !important;
  border-right: 0 !important;
}

.flatpickr-calendar.arrowTop:after {
  border-bottom-color: #D1D5DB !important;
  box-shadow: 0px 4px 6px -1px rgba(0, 0, 0, 0.10), 0px 2px 4px -2px rgba(0, 0, 0, 0.05);
}

.flatpickr-calendar {
  box-shadow: 0px 4px 6px -1px rgba(0, 0, 0, 0.10), 0px 2px 4px -2px rgba(0, 0, 0, 0.05) !important;
}

.flatpickr-innerContainer {
  border-bottom: 0 !important;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.flatpickr-day.inRange {
  background: #E8F4FF !important;
  border-color: #E8F4FF !important;
  -webkit-box-shadow: -5px 0 0 #E8F4FF, 5px 0 0 #E8F4FF !important;
  box-shadow: -5px 0 0 #E8F4FF, 5px 0 0 #E8F4FF !important;
}

.flatpickr-day:hover, .flatpickr-day.prevMonthDay:hover, .flatpickr-day.nextMonthDay:hover, .flatpickr-day:focus, .flatpickr-day.prevMonthDay:focus, .flatpickr-day.nextMonthDay:focus {
  background: #E8F4FF !important;
  border-color: #E8F4FF !important;;
}

.flatpickr-day.selected, .flatpickr-day.startRange, .flatpickr-day.endRange, .flatpickr-day.selected.inRange, .flatpickr-day.startRange.inRange, .flatpickr-day.endRange.inRange, .flatpickr-day.selected:focus, .flatpickr-day.startRange:focus, .flatpickr-day.endRange:focus, .flatpickr-day.selected:hover, .flatpickr-day.startRange:hover, .flatpickr-day.endRange:hover, .flatpickr-day.selected.prevMonthDay, .flatpickr-day.startRange.prevMonthDay, .flatpickr-day.endRange.prevMonthDay, .flatpickr-day.selected.nextMonthDay, .flatpickr-day.startRange.nextMonthDay, .flatpickr-day.endRange.nextMonthDay {
  background: #A9D8FF !important;
  border-color: #A9D8FF !important;
  //box-shadow: -5px 0 0 #A9D8FF, 5px 0 0 #A9D8FF !important;
}

.flatpickr-day.selected.startRange + .endRange:not(:nth-child(7n+1)), .flatpickr-day.startRange.startRange + .endRange:not(:nth-child(7n+1)), .flatpickr-day.endRange.startRange + .endRange:not(:nth-child(7n+1)) {
  -webkit-box-shadow: -10px 0 0 #A9D8FF !important;
  box-shadow: -10px 0 0 #A9D8FF !important;
}

.numInputWrapper:hover {
  background: #F3F4F6 !important;
}

.flatpickr-current-month .numInputWrapper span.arrowDown:after {
  border-top-color: #05243E !important;
}

.flatpickr-current-month .numInputWrapper span.arrowUp:after {
  border-bottom-color: #05243E !important;
}

//custom p-multiselect
.p-multiselect {
  width: 220px;
  border: solid 1px #E5E7EB;
  border-radius: 2px;
  padding: 5px 10px;
  margin-right: 15px;
  @media screen and (max-width: 1600px) {
    margin-top: 15px;
  }
  @media screen and (max-width: 980px) {
    margin-right: 0;
    width: 100%;
    margin-top: 10px;
  }
  .p-multiselect-token {
    background: #F3F4F6;
    border-radius: 4px;
    margin-right: 5px;
    padding: 3px 5px;
    font-size: 12px;

    .p-multiselect-token-label {

    }

    span {
      padding-right: 5px;
      color: #111928;
    }

    svg {
      width: 12px;
    }
  }
}

.p-multiselect-panel {
  background: white;
  padding: 0;
  border-radius: 0 0 8px 8px;
  border: 1px solid #ECECEC;
  border-top: 0;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.10);
}

.p-highlight {
  background: #E8F4FF;
}

.p-multiselect-label-container {
  align-items: center;
  display: flex;
  color: #6B7280;
}

.p-multiselect-item {
  display: flex;
  padding: 8px 10px;
  gap: 5px;
  border-bottom: solid 1px #F4F8F9;

  &:hover {
    background: #F4F8F9;
  }

  .flag {
    display: none;
  }
}

.p-checkbox {
  position: relative;
  margin-right: 3px;

  .p-checkbox-box {
    display: none;
  }

  [type='checkbox']:checked {
    border-radius: 2px;
  }

  [type='checkbox'] {
    border-radius: 2px;
    cursor: pointer;
  }
}

.p-multiselect-header {
  padding: 10px;
  border-bottom: solid 1px #F4F8F9;

  .p-multiselect-select-all {
    display: flex;

    label:before {
      content: "Select all";
    }
  }
}

.p-multiselect-footer {
  background: #F4F8F9;
}
.action-w-POs{
  @media screen and (max-width: 1600px) {
    .p-multiselect {
      margin-top: 0;
    }
  }
  @media screen and (max-width: 1170px) {
    .custom-date-range {
      margin-top: 0;
    }
    .custom-date-range-modal {
      margin-top: 0;
    }
    .p-multiselect {
      margin-top: 0;
    }
  }
  @media screen and (max-width: 1040px) {

    .p-multiselect {
      margin-top: 15px;
    }
  }
  @media screen and (max-width: 980px) {
    .custom-date-range {
      width: 100%;
      margin-bottom: 10px;
    }
    .custom-date-range-modal {
      width: 100%;
      margin-bottom: 10px;
    }
    .p-multiselect {
      margin-top: 0;
    }
  }

}

