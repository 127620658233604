.user-detail-container {
  .avatar-large {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 130px;
    height: 130px;
    background: #FF8A4C;
    border-radius: 50%;
    text-transform: uppercase;
    color: white;
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;
  }

  .user-summary {
    background-image: url("../../assets/image/svg/userCoverImg.svg");

    .user-avatar-container {
      .user-avatar-edit {
        display: none;
        width: 100%;
        height: 100%;
        border-radius: 50%;
        justify-content: center;
        align-items: center;
        opacity: 0.7;
        background-color: black !important;

        &:hover {
          display: flex !important;
        }
      }

      .user-avatar {
        width: 130px;
        height: 130px;
        img{
          height: 100%;
          width: 100%;
          object-fit: cover;
        }
      }

      .user-avatar:hover + .user-avatar-edit {
        display: flex !important;

      }
    }
  }

  .badge-active {
    background-color: #0E9F6E !important;
    height: 24px;

    span {
      color: white;
      text-transform: uppercase;
      font-weight: 300;
    }
  }

  .badge-inactive {
    background-color: #F05252 !important;
    height: 24px;

    span {
      color: white;
      text-transform: uppercase;
      font-weight: 300;
    }
  }

  .tab-button {
    gap: 0;
    background: #F9FAFB;
    height: 55px;
    border-bottom: solid 1px #E5E7EB;
    width: 100%;
    align-items: flex-start;
    >div:nth-of-type(2){
      width: 100%;
      padding: 30px;
      .py-3 {
        padding:0;
      }
    }
    .rounded-t-lg {
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    }



    .pl-8 {
      padding-left: 30px;
    }
  }

  .tab-group {
    position: relative;

    .tab-group {
      padding: 0;
      background: #F9FAFB;
      border-bottom: solid 1px #E5E7EB;

      button {
        box-shadow: unset !important;
        cursor: pointer;
        padding: 20px 15px;
      }
    }

  }

  .action-table {
    position: absolute;
    top: 11px;
    right: 30px;
    margin-bottom: 0;
    padding: 0;
  }

  .user-infor {
    position: relative;

    .user-detail-infor {
      background-color: white;
      padding: 15px 30px !important;
      border-radius: 2px;
      border: solid 1px #E5E7EB;

      .user-label {
        min-width: 150px;
      }

      .user-detail-infor-side {
        flex: 1;
        padding: 0;

        .user-detail-infor-row {
          padding: 15px 0;
          justify-content: flex-start;
        }
      }

      .edit-full-name {
        width: 100%;

        .input-name {
          width: 35% !important;
          padding: 0;
        }

        .edit-full-name-form {
          input {
            border-radius: 0.25rem !important;
          }

          button {
            box-shadow: unset !important;
            padding: 0;
            height: 41px;

          }

          .save-button {
            &:hover {
              --tw-bg-opacity: 1;
              background-color: #725519;
              box-shadow: 0px 0px 2px 2px #FFE6B1;
            }
          }

          .cancel-button {
            &:hover {
              --tw-bg-opacity: 1;
              background-color: #725519;
              box-shadow: 0px 0px 2px 2px #FFE6B1;
            }
          }
        }
      }
    }

    .user-detail-org {
      background-color: #FFFFFF;
      border-radius: 2px;
      border: solid 1px #E5E7EB;
      width: 100%;
      .organization{
        gap: 1rem;
        grid-template-columns: repeat(4, minmax(0, 1fr));
      }
    }

    .avatar {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 60px;
      height: 60px;
      background: #FF8A4C;
      border-radius: 30px;
      text-transform: uppercase;
      color: white;
      font-weight: 600;
      font-size: 12px;
      line-height: 18px;
    }
  }

  .p-treetable {
  }

  .p-treetable-wrapper {

    thead {
      border-bottom: solid 1px #ECECEC;
    }

    .p-column-header-content {
      padding-bottom: 10px;
    }

    button {
      padding: 0;
    }
    td{
      padding: 12px 0;
    }
  }
}

@media screen and (max-width: 1280px) {
  .user-detail-container {
    .action-table {
      position: inherit;
      top: inherit;
      right: inherit;
      flex-direction: row;
      display: flex;
      justify-content: flex-end;
      margin-bottom: 15px;
      .search {
        .w-72 {
          width: 14rem;
        }
      }
      .action-search{
        margin-bottom: 0;
      }
      .action-button {
        justify-content: flex-end;
        margin-top: 0;
      }
    }
    .user-infor {
      .user-detail-infor {
        flex-direction: column;
      }
      .user-detail-org {
        .organization{
          grid-template-columns: repeat(2, minmax(0, 1fr));
        }
      }
    }
  }
}
@media screen and (max-width: 940px) {
  .user-detail-container {
    .tab-button > div:nth-of-type(2) {
      padding: 15px;
    }


  }

}