.select-custom {

  &.select-multi .react-select__menu-list {
    padding: 10px;
    padding-bottom: 0px;
    background: white;
  }

  .react-select__option {
    width: auto;
    padding: 10px;
    color: #301400;
    background: #F9FAFB;
    font-style: normal;
    font-weight: 400;
    cursor: pointer;
    display: flex;
    align-items: center;
    border-radius: 2px;
    div{
      margin-right: 10px;
    }
  }

  &.select-multi .react-select__option {
    font-size: 12px;
    float: left;
    padding: 6px 10px;
    margin-bottom: 6px;
    margin-right: 10px;
    border-radius: 4px;
    //border: 1px solid #F3F4F6;
    background: #F9FAFB;
  }

  &.custom-single .react-select__single-value {
    font-size: 12px;
    float: left;
    padding: 3px 10px;
    margin-left: 0;
    margin-right: 10px;
    border-radius: 4px;
    border: 1px solid #ECECEC;
    width: fit-content;
    background: rgba(132, 116, 106, 0.20) !important;
  }

  .react-select__option--is-focused {
    background: #E8F4FF !important;
  }

  .react-select__option--is-selected {
    background: #E8F4FF !important;
    color: #111928;
  }
  // custom select multi avatar
  .react-select__multi-value__label {
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    color: #301400;
  }

  .react-select__multi-value__remove {
    color: #84746A;
  }

  .react-select__placeholder {
    color: #84746A
  }
  .react-select__indicator {
    svg{
      color: #6B7280;
    }
  }
  .css-1xc3v61-indicatorContainer{
    padding: 5px 0 5px 5px;
  }
  .css-1hb7zxy-IndicatorsContainer{
    padding-right: 5px;
  }
  .react-select__value-container {
    min-height: 44px;
  }
  .css-3w2yfm-ValueContainer, .css-3w2yfm-ValueContainer{
    div{
      width: auto !important;
    }
  }
  .css-wsp0cs-MultiValueGeneric{
    display: flex;
    align-items: center;
    div:first-of-type{
      display: none;
    }
  }
  .css-1p3m7a8-multiValue{
    background: #F9FAFB;
    color: #111928;
  }

  .css-1nmdiq5-menu{
    z-index: 999;
  }

  //
  .holder {
    font-size: 14px;
    line-height: 16px;
    position: absolute;
    color: #6B7280;
    left: 5px;
    top: 24px;
    transform: translateY(-50%);
    transition: all 0.25s ease;
    pointer-events: none;
    background: none;
    //padding: 0 5px;
    z-index: 1;
  }

  .label {
    background: white;
    border-radius: 6px;
    left: 10px;
    top: 0;
    font-size: 12px;
  }

  .react-select__control {
    background: none !important;
    border-radius: 2px;
    border: 1px solid #ECECEC;

    &--is-focused {
      box-shadow: 0 0 0 1px rgb(132, 116, 106, 1);
      border-color: #AB9973 !important;
    }
  }

  .css-1n6sfyn-MenuList{
    max-height: 230px;
  }
}