//media
.location-modal{
    background: white;
    border-radius: 10px;
    padding: 30px;
    flex:1;
}

.location-modal-container{
    .max-h-\[90vh\] {
        max-height: 100vh;
    }
    form{
        width: 100%;
    }

}
//
//@media (max-height: 500px) {
//    .location-modal-container {
//        .location-modal {
//            >div {
//                height: 100% !important;
//            }
//        }
//
//        ::-webkit-scrollbar {
//            display: none;
//        }
//    }
//}
//
//@media screen and (min-width: 1025px) {
//    .location-modal-container {
//        .location-modal {
//            .grid-cols-custom {
//                grid-template-columns: repeat(4, minmax(0, 1fr));
//            }
//
//            .gap-custom {
//                gap: 1rem;
//            }
//        }
//    }
//}
//
//@media screen and (max-width: 1024px) {
//    .location-modal-container {
//        .location-modal {
//            >div {
//                height: unset !important;
//            }
//
//            .grid-cols-custom {
//                grid-template-columns: repeat(2, minmax(0, 1fr));
//            }
//
//            .gap-custom {
//                gap: 0.5rem;
//            }
//        }
//    }
//}
//
//@media screen and (max-width: 640px) {
//    .location-modal-container {
//        .location-modal {
//            .grid-cols-custom {
//                grid-template-columns: repeat(1, minmax(0, 1fr));
//
//                div[class='flex'] {
//                    margin-bottom: 1rem;
//                }
//            }
//        }
//    }
//}