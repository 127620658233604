.user-management {
    tr {
        &:hover {
            .action-three-dots {
                background-color: #E1EFFE !important;
            }
        }
    }
    @media screen and (max-width: 640px) {
    }
}