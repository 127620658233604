.organization{
    //position: relative;
}
.organization-item {
    .organization-item-avt {
        width: 25%;

        img {
            width: 60px;
            height: 60px;
        }
    }

    .organization-item-infor {
        width: 75%;
    }
}

@media only screen and (max-width: 640px) and (min-width: 500px) {
    .organization-item {
        .organization-item-avt {
            width: 20%;
        }

        .organization-item-infor {
            width: 80%;
        }
    }
}

@media screen and (max-width: 400px) {
    .organization-item {
        .organization-item-avt {
            width: 30%;
        }

        .organization-item-infor {
            width: 70%;
        }
    }
}