.subscription-page {
  background-color: #F9FAFB;
  height: fit-content;

  .back {
    &:hover {
      opacity: 0.7;
    }
  }

  .subscription-title {
    padding-bottom: 10px;
    font-size: 48px;
    font-weight: 600;
    letter-spacing: 0em;
    text-align: center;
    color: #6B7280;

    @media (max-width: 590px) {
      font-size: 25px;
      font-weight: 600;
    }
  }

  .subscription-description {
    width: 610px;
    font-size: 18px;
    font-weight: 400;
    text-align: center;
    color: #6B7280;

    @media (max-width: 590px) {
      width: 350px;
      text-align: center;
    }
  }

  .plans {
    display: flex;
    flex-direction: row;
    justify-content: center;

    @media (max-width: 590px) {
      flex-direction: column;
    }
  }

  .plan-item {
    margin: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    text-align: center;
    color: #6B7280;

    &.active {
      height: 520px;
      padding: 10px 4px 4px;
      border-radius: 10px;

    }

    .plan-item-header {
      text-align: center;
      color: white;
      padding-bottom: 10px;

    }

    .plan-item-body {
      background-color: white;
      width: 380px;
      height: 360px;
      padding: 30px;
      border-radius: 10px;
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;

      .plan-title {
        font-style: normal;
        font-weight: 500;
        font-size: 24px;
      }

      .plan-currencies {
        margin-bottom: 16px;
        color: #111928;
        font-weight: 800;
        font-size: 48px;

        .plan-currency {
          position: relative;
          top: -20px;
          right: 3px;
          font-size: 18px;
          font-weight: 400;
          line-height: 18px;
          color: #6B7280;
        }

        .plan-amount {
          font-size: 50px;
          font-weight: 700;
          color: #1C64F2;
        }

        .plan-interval {
          font-size: 20px;
          font-weight: 400;
          color: #6B7280;
        }
      }



      .plan-action {
        button {
          width: 320px;
          height: 50px;
          font-weight: 700;
          font-size: 20px;
          white-space: nowrap;
          padding: 5px 15px;

          &:hover {
            color: white;
            opacity: 0.9;
          }
        }

        &.basic {
          button {
            background: #EBF5FF;
            color: #3F83F8;
          }
        }

        &.standard {
          button {
            background: #3F83F8;
          }
        }

        &.enterprise {
          button {
            background: #DEF7EC;
            color: #0E9F6E;
          }
        }
      }

      .plan-description {
        text-align: left;
        min-height: 100px;
        width: 320px;
        height: 102px;

        li {
          list-style: none;
          display: flex;
          align-items: center;

          &::before {
            display: flex;
            align-items: center;
            content: "\2022";
            margin-right: 5px;
            font-size: 32px;
            line-height: 26px;
            margin-top: -2px;
          }
        }
      }
    }
  }

}