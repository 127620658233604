//media
.item-modal {
  .max-h-\[90vh\] {
    max-height: 800px;
    overflow-y: auto;
  }

  .add-UPCs {
    button {
      background: #F3F4F6;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 2px;
      text-transform: capitalize;
      padding: 0 10px;
      height: 34px;
      margin-top: 5px;

      &:hover {
        color: black;
      }

      svg {
        margin-right: 5px;
      }
    }
  }

  .upcs-table {
        margin-top: 5px;
    border-radius: 4px;
    box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.10), 0px 1px 2px -1px rgba(0, 0, 0, 0.10);
    table {
      width: 100%;
      tbody{

      }
    }

    .custom-input {
      input {
        height: 34px;
        border-radius: 2px;
        background: white;
        border: #ECECEC solid 1px;
      }
    }
  }
}

@media (max-height: 500px) {
  .item-modal-container {
    .item-modal {
      > div {
        height: 100% !important;
      }
    }

    ::-webkit-scrollbar {
      display: none;
    }
  }
}

@media screen and (min-width: 1025px) {
  .item-modal-container {
    .item-modal {
      .grid-cols-custom {
        grid-template-columns: repeat(4, minmax(0, 1fr));
      }

      .gap-custom {
        gap: 1rem;
      }
    }
  }
}

@media screen and (max-width: 1024px) {
  .item-modal-container {
    .item-modal {
      > div {
        height: unset !important;
      }

      .grid-cols-custom {
        grid-template-columns: repeat(2, minmax(0, 1fr));
      }

      .gap-custom {
        gap: 0.5rem;
      }
    }
  }
}

@media screen and (max-width: 640px) {
  .item-modal-container {
    .item-modal {
      .grid-cols-custom {
        grid-template-columns: repeat(1, minmax(0, 1fr));

        div[class='flex'] {
          margin-bottom: 1rem;
        }
      }
    }
  }
}