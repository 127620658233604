.capitalize {
  text-transform: capitalize;
}

@media (max-width: 1600px) {
  .custom-w-full {
    width: 60%;
  }
}

@media (min-width: 0) and (max-width: 1300px) {
  .custom-w-full {
    width: 76%;
  }
}

@media (min-width: 0) and (max-width: 1100px) {
  .custom-w-full {
    width: 100%;
  }
}

//media
@media screen and (max-width: 1024px) {
  .hidden-mobile-tablet {
    display: none;
  }
}

@media screen and (max-width: 768px) {
  .hidden-tablet {
    display: none;
  }
}

@media screen and (max-width: 768px) {
  .hidden-mobile {
    display: none;
  }

  .w-1\/2 {
    width: 100%;
  }
}

/*Toast*/
.Toastify__toast-icon {
  width: 24px !important;
}

.Toastify__close-button--light {
  color: #111827 !important;
  opacity: 1 !important;
  align-self: center !important;
}

.Toastify__toast {
  border-radius: 10px !important;
  padding: 0 15px !important;
  box-shadow: 0px 4px 6px -1px rgba(0, 0, 0, 0.1), 0px 2px 4px -2px rgba(0, 0, 0, 0.05) !important;
}

.Toastify__toast-container {
  padding: 0 !important;
}

.Toastify__toast-theme--light {
  color: #111827 !important;
  font-size: 14px !important;
}

.Toastify__toast-body {
  padding: 0 !important;
}

//
.break-word {
  word-break: break-word;
}

.border-error {
  input {
    border: 1px solid rgb(220, 38, 38, .5);

    &:focus {
      --tw-border-opacity: 1;
      border: solid 2px rgb(240 82 82/var(--tw-border-opacity));
      box-shadow: unset !important;
    }
  }

}

.border-error-select {
  .css-13cymwt-control {
    border: 1px solid rgb(220, 38, 38, .5) !important;

    :focus {
      --tw-border-opacity: 1 !important;
      border: solid 2px rgb(240 82 82/var(--tw-border-opacity)) !important;
      box-shadow: unset !important;
    }
  }
}

.react-select {
  &.error {
    .react-select__control {
      border-color: red;
      box-shadow: none;

      &:focus-within {
        border-width: 2px;
      }
    }
  }

  &__control {
    border-width: 1px;
    border-radius: 0.5rem;
    font-size: 0.875rem;
    line-height: 1.25rem;
    background-color: #F9FAFB;
  }

  &__value-container {
    height: inherit;
    padding: 0.625rem;
    border: none;
    color: #2B5873;
    align-content: center;

    &:focus-within .react-select__placeholder {
      display: none;
    }

    &--is-multi {
      display: flex;
      flex-wrap: nowrap;
    }

    .react-select__input-container {
      .react-select__input {
        &:focus {
          box-shadow: none;
        }
      }
    }

  }

  &__single-value {
    color: #2B5873;
  }

  &__indicator-separator {
    display: none;
  }

  &__indicator {
    display: flex;
    color: #2B5873;

    svg {
      cursor: pointer;

      &:hover {
        border-radius: 5px;
        background-color: #e5e7eb
      }
    }
  }

  &__placeholder {
    color: #2B5873;
  }

  &__menu {
    border-width: none;
    border-radius: 0.5rem;

    &-list {
      border-width: none;
      border-radius: 0.5rem;
      font-size: 0.875rem;
      line-height: 1.25rem;
      background-color: #F9FAFB;
      color: #2B5873;
    }
  }

  &__option {
    color: #2B5873;
  }

  &__multi-value__label {
    color: #2B5873;
  }

}

input {
  &:disabled {
    opacity: 50% !important;
  }
}

.action-three-dots {
  width: 34px;
  height: 30px;
  border-radius: 3px;
}


.dropdown-three-dots {

  > div {
    position: absolute !important;
    z-index: 10;
  }

  .dropdown {
    position: absolute !important;
    z-index: 10 !important;
  }

  > button {
    width: 34px !important;
    height: 34px !important;
    border-radius: 3px !important;
    box-shadow: unset !important;


    span {
      padding: 0 0 !important;

      div {
        svg {
          color: #301400
        }
      }
    }
  }

  ul {
    right: 0;
  }
}

td {
  .copy-icon {
    visibility: hidden;
  }

  &:hover {
    .copy-icon {
      visibility: visible;
    }
  }
}

.icon-sort {
  visibility: hidden;
}

th {
  &:hover {
    .icon-sort {
      visibility: visible;
    }
  }
}

//custom button hover
button {
  &:hover {
    box-shadow: 0 0 2px 2px #FFE6B1;
  }
}

.h-page {
  height: calc(100vh - 72px);
  //height: 100vh;
  @media screen and (max-width: 770px) {
    height: 100vh;
    //height: calc(100vh - 56px);

  }
}

.main-content {
  //width: calc(100% - 16rem);
  width: 100%;
  overflow-y: auto;
  @media screen and (max-width: 768px) {
    margin-top: 40px;
    width: 100%;
  }
}
.main-sub{
  padding: 30px 30px 92px 30px;
  width: 100%;

}

//custom-input
.custom-input{
  input{
    border-radius: 2px;
    border-color: #ECECEC;
    background: white;
  }
}
//custom scrollbar
::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  border-radius: 10px;
}
::-webkit-scrollbar-track-piece {
  background: #E5E7EB;
}
::-webkit-scrollbar-thumb {
  background: #9CA3AF;
  border-radius: 10px;
}
