@import './mixins';

//for mobile/Tablet
@media screen and (max-width: 768px) {
  .mt-content-mb {
    margin-top: 76px;
    padding-bottom: 76px;
  }

  .mt-dashboard-mb {
    margin-top: 74px;
    overflow-y: unset !important;
    overflow-x: unset !important;
    box-shadow: unset !important;
    border: unset !important;
  }

  .nav-menu-mobile {
    z-index: 1;
    position: fixed;
    background: #05243E;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 15px;
    --tw-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
    --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color), 0 2px 4px -2px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .show-dropdow-menu {
    transition: color 1s cubic-bezier(0.32, 0, 0.67, 0);
    transition: 0.5s;
    z-index: 3;
    position: fixed;
    background: white;
    width: 100%;
    height: 100%;
    --tw-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
    --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color), 0 2px 4px -2px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }
}

.avatar-size {
  width: 34px;
  height: 34px;
}
.requestedPOs{
  li{
    background-color: #FDF2F2;
    .text-darkSecondary{
      color: #E02424;
    }
    .bg-darkSecondary{
      background-color: #E02424;
    }
  }
}
.collapse-expand{
  z-index: 10;
  border-radius: 100px;
  border: 1px solid var(--gray-100, #F3F4F6);
  background: #F9FAFB;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.1);
  display: flex;
  width: 24px;
  height: 24px;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: -12px;
  bottom: 10%;
  --tw-ring-shadow: transparent;
}