.primary-button {
  button {
    background: #014C8C;
    border-color: #014C8C;
  }
}

//media
.stock-modal, .stock-modal-manually {
  min-height: 480px;

  .max-h-\[90vh\] {
    min-height: 520px;
    max-height: 800px;
  }

  .wrap-content {
    padding: 0 30px 30px 30px;
  }
}

@media (max-height: 500px) {
  .stock-modal-container {
    .stock-modal {
      > div {
        //height: 100% !important;
      }
    }

    ::-webkit-scrollbar {
      display: none;
    }
  }
}

@media screen and (min-width: 1025px) {
  .stock-modal-container {
    .stock-modal {
      .grid-cols-custom {
        grid-template-columns: repeat(4, minmax(0, 1fr));
      }

      .gap-custom {
        gap: 1rem;
      }
    }
  }
}

@media screen and (max-width: 1024px) {
  .stock-modal-container {
    .stock-modal {
      > div {
        height: unset !important;
      }

      .grid-cols-custom {
        grid-template-columns: repeat(2, minmax(0, 1fr));
      }

      .gap-custom {
        gap: 0.5rem;
      }
    }
  }
}

@media screen and (max-width: 640px) {
  .stock-modal-container {
    .stock-modal {
      .grid-cols-custom {
        grid-template-columns: repeat(1, minmax(0, 1fr));

        div[class='flex'] {
          margin-bottom: 1rem;
        }
      }
    }
  }
}
.padding-bottom{
  padding-bottom: 30px;
}
.reconcile-table {
  vertical-align: top;
  display: flex;
  flex-direction: column;
  margin-top: 25px;

  thead {
    tr {
      align-items: end;
      th{
        padding-bottom: 5px;
      }
    }
  }

  tbody {
    tr {
      width: 100%;
      display: flex;
      height: 100%;
      padding-bottom: 5px;

      td {
        div {
          p {
            display: none;
          }
        }

      }
    }
  }

  .select-item {
    .css-13cymwt-control {
      width: 290px;
    }
  }

  .select-reason {
    .select-custom .react-select__control {
      width: 2100px;
    }
  }

  input {
    border-radius: 2px;
    border-color: #ECECEC;
    background: white;
  }

  .focus\:ring-cyan-500:focus {
    --tw-ring-color: #AB9973;
  }

  .focus\:border-cyan-500:focus {
    border-color: transparent;
  }

  .item {
    width: 240px;
    vertical-align: bottom;
    margin: 15px 0 0 0;

    .select-custom .react-select__control {
      width: 240px;
    }
  }

  .previous {
    width: 120px;
    margin: 15px 15px 0 15px;
    vertical-align: bottom;
  }

  .current {
    width: 120px;
    vertical-align: bottom;
    input {
      width: 120px;
    }
  }

  .reason {
    width: 170px;
    vertical-align: bottom;
    margin: 0 15px;

    .select-custom .react-select__control {
      width: 170px;
      height: 42px;
      margin: 0 15px;
    }
  }

  .discrepency {
    width: 230px;
    margin-right: 10px;
    vertical-align: bottom;
  }

  .action {
    width: 100px;
  }

  input {
    border-radius: 2px;
    border-color: #ECECEC;
    background: white;
  }

  .focus\:ring-cyan-500:focus {
    --tw-ring-color: #AB9973;
  }

  .focus\:border-cyan-500:focus {
    border-color: transparent;
  }
}

.fixed-group-buttons {
  position: absolute;
  left: 0;
  right: 10px;
  bottom: 0;
  background: white;
  padding: 20px 30px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.hover\:bg-blue-100:hover {
  background-color: rgb(171 153 115);
}

//ImportShipmentModal
.import-shipment-modal {
  width: 100%;

  .select-custom .react-select__value-container {
    min-height: 26px;
  }
}
