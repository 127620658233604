.bullet {
  width: 7px;
  height: 7px;
  border-radius: 20px;
}

.hide-status {
  display: none;
}

.veri-modal {
  overflow: hidden;
  transition: height 0.2s;
  height: 0px;
}
.warning {
  overflow: hidden;
  font-size: 11px;
  transition: height 0.2s;
  line-height: 14px;
}

.height-80 {
  height: 80px;
}

.height-16 {
  height: 16px;
}

.custom-multi-select {
  .css-qbdosj-Input {
    margin: 0;

    input {
      //min-width: 200px !important;
    }
  }

  .p-2\.5 {
    padding: 0;
  }

  .css-13cymwt-control,
  .css-t3ipsp-control {
    min-height: 42px;
    border-radius: 0.5rem;
    background-color: #F9FAFB;
  }

  [type=text]:focus,
  [type=time]:focus,
  [type=url]:focus,
  [type=week]:focus,
  select:focus,
  textarea:focus {
    --tw-ring-inset: var(--tw-empty,
        /*!*/
        /*!*/
      );
    border-color: transparent;
    box-shadow: none;
    outline: 0 solid transparent;
  }

  .css-3w2yfm-ValueContainer {
    padding: 4px 2px;
  }

  .css-1p3m7a8-multiValue {
    margin: 2px 0 2px 4px;
  }

  .css-1xc3v61-indicatorContainer {
    color: #111928;
  }
}

input[type='checkbox'].custom-checkbox {
  border-radius: 2px;
  height: 18px;
  margin-right: 3px;
  width: 18px;
  background-color: white;
  border: solid 1px #AB9973
}

input[type='checkbox'].custom-checkbox.disabled {
  background-color: #F9FAFB;
  border: solid 1px #D1D5DB;
}

input[type='checkbox'].custom-checkbox:checked {
  border-radius: 2px;
  height: 18px;
  margin-right: 3px;
  width: 18px;
  background-color: #AB9973;
  border: solid 1px #AB9973
}
input[type='checkbox'].custom-checkbox:checked.disabled {
  background-color: #F9FAFB;
  border: solid 1px #D1D5DB;
}

.post-fix-width {
  max-width: 150px;
}
