@import '../../assets/scss/variable';

.paginate {
  position: fixed;
  bottom: 0;
  right: 0;
  z-index: 1;
  //left:0;
  padding: 30px;
  width: calc(100% - 16rem);
  background: white;
  font-size: 14px;
  @media only screen and (max-width: 390px) {
    padding: 15px;
    //width: calc(100% - 30px);
  }

  .next-button, .previous-button {
    background-color: $secondary;
    height: 100%;
    padding: 0 15px !important;
    font-size: 14px;
    border-radius: 2px;

    &:hover {
      box-shadow: 0 0 2px 2px #FFE6B1;
    }
  }

  .previous-button {
    background: white;
    border-color: $border;

    &:hover {
    }
  }

  .paginate-icon {
    background-color: $next_back_icon;
    border-radius: 2px;

    &:hover {
      box-shadow: 0 0 2px 2px #FFE6B1;
      border-radius: 2px;
    }
  }

  .paginate-input {
    height: 100%;
  }

  input {
    border-color: $border;
    &:focus {
      box-shadow: 0 0 2px 2px #FFE6B1;
    }
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}

@media screen and (max-width: 1400px) {
  .paginate {
    .paginate-button {
      width: 66% !important;
    }
  }
}

@media screen and (max-width: 820px) {
  .paginate {

    .paginate-button {
      justify-content: flex-start;

      .previous-button-container {
        margin-left: 0 !important;
      }

      .next-button,
      .previous-button {
        font-size: 0;

        svg {
          margin-right: 0px;
          margin-left: 0px;
        }
      }

    }

    .second-paginate {
      margin-right: 0px !important;
      padding-right: 0px !important;
    }
  }
}