.header {
    background-color: #05243E;
    width: 100% !important;

    .logo-container {
        position: relative;
        width: 130px;
        height: 56px;
    }

    .logo-container:after {
        content: '';
        height: 20px;
        width: 1px;

        position: absolute;
        right: 0;
        top: 18px;

        background-color: rgba(57, 109, 124, 1);
    }
}
@media screen and (max-width: 768px) {
    .header {
        .user-profile {
            display: none;
            cursor: pointer;
        }

        align-items: unset !important;

        >div {
            display: block;
        }

        box-shadow: unset !important;
    }
}
